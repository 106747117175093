import {Fragment, useContext, useState} from "react";
import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";

import DataContext from "../store/data-context";
import loadable from "@loadable/component";
import Header from './Layout/Header';
import SideBar from './Layout/SideBar';
import {ReactComponent as Symbols} from "../ynex-ts/preview/flags.svg";


const Dashboard = React.lazy(() => import("./panel/dashboard"));
const Domain = React.lazy(() => import("./panel/domin"));
const Semiautomatic = React.lazy(() => import("./panel/semiautomatic"));
const PaymentGateway = React.lazy(() => import("./panel/paymentGateway"));
const Charge = React.lazy(() => import("./panel/charge"));
const Transactions = React.lazy(() => import("./panel/transactions"));
const Docs = React.lazy(() => import("./panel/docs"));
const StaticCard = React.lazy(() => import("./panel/staticCard"));
const Withdraw = React.lazy(() => import("./panel/withdraw"));
const Employ = React.lazy(() => import("./panel/employ"));
const CurrencySetttings = React.lazy(() => import("./panel/currencySetttings"));
const Setting = React.lazy(() => import("./panel/setting"));
const Support = React.lazy(() => import("./panel/support"));
const CurrencyWallet = React.lazy(() => import("./panel/currencywallet"));
const TransactionLogs = React.lazy(() => import("./panel/transactionLogs"));
const Bans = React.lazy(() => import("./panel/bans"));
const Settlementlist = React.lazy(() => import("./panel/settlementlist"));
const Reports = React.lazy(() => import("./panel/reports"));
const Login = loadable(() => import("./Auth/Login"));

const Index = (props) => {
    const ctx = useContext(DataContext);

    if (ctx.setting.lang === 'IRN') {
        ctx.htmlReader.setAttribute('data-country', ctx.setting.lang)
        ctx.htmlReader.setAttribute('dir', 'rtl')
        ctx.htmlReader.setAttribute('lang', 'fa')
    } else {
        ctx.htmlReader.setAttribute('data-country', ctx.setting.lang)
        ctx.htmlReader.setAttribute('dir', 'ltr')
        ctx.htmlReader.setAttribute('lang', 'en')
    }
    if (ctx.authToken === '') {
        return <React.Suspense fallback={<ctx.Loading/>}><Login ctx={ctx}/></React.Suspense>
    }
    return <React.Suspense fallback={<ctx.Loading/>}>
        <div className={'page'}

        >
            <div style={{display: 'none'}}><Symbols/></div>
            <Header ctx={ctx}/>
            <div id="responsive-overlay" onClick={() => {
                ctx.htmlReader.setAttribute('data-toggled', 'close');
                ctx.setClose(false)
            }}></div>

            <SideBar ctx={ctx}/>
            {/*<ctx.Loading/>*/}
            <br/>
            <React.Suspense fallback={<ctx.Loading/>}>
                <div className={'main-content app-content'}>
                    <div className={'container-fluid'}>
                        <Switch>
                            <Route exact path={window.configX.root + "/dashboard"}>
                                <Dashboard ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/charge"}>
                                <Charge ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/transactions"}>
                                <Transactions ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/gateways"}>
                                <PaymentGateway ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/dstcards"}>
                                <StaticCard ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/currency"}>
                                <CurrencySetttings ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/withdraw"}>
                                <Withdraw ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/employ"}>
                                <Employ ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/bans"}>
                                <Bans ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/setting"}>
                                <Setting ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/currencywallet"}>
                                <CurrencyWallet ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/support"}>
                                <Support ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/settlementlist"}>
                                <Settlementlist ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/reports"}>
                                <Reports ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/docs"}>
                                <Docs ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/transactionlogs"}>
                                <TransactionLogs ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/semiautomatic"}>
                                <Semiautomatic ctx={ctx}/>
                            </Route>
                            <Route exact path={window.configX.root + "/domain"}>
                                <Domain ctx={ctx}/>
                            </Route>
                            <Route path="/*">
                                <Dashboard ctx={ctx}/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </React.Suspense>

        </div>
    </React.Suspense>

}
export default Index;
