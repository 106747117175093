import React, {Fragment, useEffect, useRef, useState} from "react";

const Index = ({
                   Cols,
                   url,
                   filter,
                   submitCounter,
                   lengthMenu = [15, 30, 50, 100],
                   dataTmp = null,
                   searchBox = false,
                   setTableRes,
                   tableRes,
                   ctx,
                   refresh,
                   customConfig = ''
               }) => {

    const djk = 15;
    const [start, setStart] = useState(0);
    const [recordsFiltered, setRecordsFiltered] = useState(null);
    const [open, setOpen] = useState(null);
    const [changeSize, setChangeSize] = useState(window.innerWidth);
    const [pages, setPages] = useState(null);
    const [data, setData] = useState([]);
    const [length, setLength] = useState(djk);
    const [changeOrder, setChangeOrder] = useState(false);
    const [changeOrderKey, setChangeOrderKey] = useState(0);
    const [refreshOldDataAfterSearchKey, setRefreshOldDataAfterSearchKey] = useState(false);
    const [loading, setLoading] = useState(false)
    const [changeOrderValue, setChangeOrderValue] = useState('');
    const order = useRef({})
    const colSpan = useRef(0)
    const responsivePriority = useRef([])
    var pageCounter = false
    const oldDataAfterSearchKey = useRef(0)
    const limitCal = useRef([])
    const [searchInput, setSearchInput] = useState('')

    const oldDataAfterSearch = useRef([])



    function cji(){

        if (searchBox === true && oldDataAfterSearchKey.current === 1) {


            const vd = JSON.stringify(oldDataAfterSearch.current);


            if (vd === '{}') {
                return false;
            }

            var df = JSON.parse(vd, true);

            var sh = [];
            setRecordsFiltered(df.length)
            df.sort(function (a, b) {
                if (order.current[changeOrderValue] === 'asc') {
                    return b[changeOrderValue] - a[changeOrderValue];
                } else {
                    return a[changeOrderValue] - b[changeOrderValue];
                }

            });

            df.slice(start * length, (start * length) + length).forEach(function (v) {
                if (searchInput === '') {
                    sh.push(v);
                } else {
                    if (JSON.stringify(v).includes(searchInput)) {
                        sh.push(v);
                    }
                }

            })
            setData(sh)
            return;

        }

    }

    function submit() {


        if (dataTmp !== null) {
            const vd = JSON.stringify(dataTmp);

            if (vd === '{}') {
                return false;
            }

            var df = JSON.parse(vd, true);

            var sh = [];
            setRecordsFiltered(df.length)
            df.sort(function (a, b) {
                if (order.current[changeOrderValue] === 'asc') {
                    return b[changeOrderValue] - a[changeOrderValue];
                } else {
                    return a[changeOrderValue] - b[changeOrderValue];
                }

            });
            df.slice(start * length, (start * length) + length).forEach(function (v) {
                if (searchInput === '') {
                    sh.push(v);
                } else {
                    if (JSON.stringify(v).includes(searchInput)) {
                        sh.push(v);
                    }
                }

            })
            setData(sh)
            return;
        }

        if (submitCounter === 0) return;

        if (searchBox === true && oldDataAfterSearchKey.current === 1) {

            cji()
            return
        }

        if(url.includes('/panel')){
            var xx = url.replace('/panel/', '').replace('/','_');
            if(ctx.checkPremision(xx) === false){
                return  false;
            }
        }

        var inf = {
            columns: [],
            order: [{
                column: 0,
                dir: 'desc'
            }]
        };

        Cols && Cols.forEach(function (v, ke) {

            inf.columns.push({
                'data': v.data,
                'name': '',
                'orderable': false
            })
            if (order.current[v.data] !== undefined) {
                inf.columns[ke]['orderable'] = true
            }
        })

        inf.order[0]['column'] = changeOrderKey;
        inf.order[0]['dir'] = order.current[changeOrderValue] === undefined ? 'desc' : order.current[changeOrderValue];
        let params = new URLSearchParams();
        inf.columns.forEach((column, index) => {
            for (let key in column) {
                if (typeof column[key] === 'object') {
                    for (let subKey in column[key]) {
                        params.append(`columns[${index}][${key}][${subKey}]`, column[key][subKey]);
                    }
                } else {
                    params.append(`columns[${index}][${key}]`, column[key]);
                }
            }
        });
        inf.order.forEach((item, index) => {
            for (let key in item) {
                params.append(`order[${index}][${key}]`, item[key]);
            }
        });


        filter && Object.keys(filter).forEach(function (v) {

            if (['requestTimeFrom', 'requestTimeTo', 'actionTimeFrom', 'actionTimeTo', 'timeFrom', 'timeTo'].includes(v) && filter[v] !== null) {
                params.append(v, ctx.timestampToUtcTimestamp(filter[v]['_d'].getTime() / 1000, 0));
            } else if (['requestTimeFrom', 'requestTimeTo', 'actionTimeFrom', 'actionTimeTo', 'timeFrom', 'timeTo'].includes(v) && filter[v] === null) {
                params.append(v, '');
            } else {
                params.append(v, filter[v]);
            }
        })
        params.append('start', start * length);
        params.append('length', length);
        params.append('token', ctx.authToken);

        setLoading(true)


        fetch(window.configX.url + url, {
            method: 'POST',
            body: params
        })
            .then(async response => {

                setLoading(false)
                if (response.status !== 200) {
                    ctx.NotificationButton('error', ctx.translator('Unfortunately, an error has occurred, please contact support'))
                    return false;
                }
                const res = await response.json();


                if (res === undefined) return false;

                if (res.status !== undefined && res.status === -1) {

                    ctx.NotificationButton('error', ctx.translator(res.detail))
                    if (res.message === 'auth5') {
                        localStorage.removeItem('authToken')
                        ctx.setAuthToken('')
                    }
                    return false;
                }

                if (setTableRes !== undefined) {
                    setTableRes(res)
                }

                setRecordsFiltered(res.recordsFiltered === undefined ? null : res.recordsFiltered)
                setPages(res.pages === undefined ? null : (res.pages === 0 ? null : res.pages))


                oldDataAfterSearchKey.current = 1;
                setRefreshOldDataAfterSearchKey(!refreshOldDataAfterSearchKey)


                oldDataAfterSearch.current = res.data;

                if (searchBox === true && oldDataAfterSearchKey.current === 1) {

                    cji()
                    return
                }
                setData(customConfigFunc(res.data))
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
            });

    }

    function customConfigFunc(data){
        if(customConfig === '')return data;

        if(customConfig === 'settle'){
            if(data.length >0 && data[data.length -1].id === "Live" ){
                var lastObject = data.pop();
                data.unshift(lastObject);
            }
        }
        return data;
    }

    function paginations() {


        var io = start;
        var st = start - 2;
        var dl = start + 2;

        var df = [];
        for (st; st < start; st++) {
            const dn = st;


            if (st === start && data.length === 0) {
                continue;
            }
            if (st > -1) {

                df.push(<li key={'ssd546s5d656sd5ds' + st} onClick={() => {

                    if (dn !== start) {
                        setStart(dn)
                    }

                }} data-iddd={st} className={"page-item " + (st === start ? 'active' : '')}>
                    <span style={{cursor: 'pointer'}} aria-current="page"
                          className="page-link">{st + 1}</span>
                </li>)
            }

        }

        if (data === undefined) {
            return df;
        }

        for (io; io < dl; io++) {
            const dn = io;


            if (data === undefined) {
                break;
            }

            if (start < dn && data.length !== length) {
                pageCounter = true;

                continue;
            }

            if (st === start && data.length === 0) {
                continue;
            }

            if (pages !== null && io < pages) {
                df.push(<li key={'ssd56s5d656sdds' + io}
                            onClick={() => {
                                if (dn !== start) {
                                    setStart(dn)
                                }
                            }}
                            className={"page-item " + (io === start ? 'active' : '')}>
                    <span style={{cursor: 'pointer'}} aria-current="page"
                          className="page-link">{io + 1}</span>
                </li>)
            } else if (pages === null) {
                df.push(<li key={'sdd65f6d5f65t8r78rts' + io} onClick={() => {
                    if (dn !== start) {
                        setStart(dn)
                    }
                }} className={"page-item " + (io === start ? 'active' : '')}>
                    <span style={{cursor: 'pointer'}} aria-current="page"
                          className="page-link">{io + 1}</span>
                </li>)
            }
        }

        return df;


    }


    function showTbl(responsive, data , index) {
        responsive = parseInt(responsive)
        if (limitCal.current.includes(responsive)) {
            return true;
        }

        return false;
    }

    function makeWidth(v) {


        if (window.innerWidth > 992) {
            return 180;
        }
        return 120;
    }

    function checkerTable() {


        var countCols = Cols.length;
        var calcPage = window.innerWidth / makeWidth();

        if (countCols > calcPage) {
            limitCal.current = checklimit([], responsivePriority.current, parseInt(calcPage), countCols)
        } else {
            limitCal.current = [];
        }
    }

    function checklimit(limit, responsivePriority, calcPage, countCols) {

        if (countCols - limit.length === 0) {
            return limit
        }
        if (responsivePriority[limit.length] !== undefined) {
            limit.push(parseInt(responsivePriority[limit.length]))
            var listColNew = 0;

            Cols.forEach(function (v) {
                if (limit.includes(parseInt(v['responsivePriority']))) {
                    listColNew++;
                }
            })

            if (countCols - listColNew > calcPage) {
                return checklimit(limit, responsivePriority, calcPage, countCols);
            }
            return limit

        }
        return limit;
    }

    const handleResize = () => {

        checkerTable()

        setChangeSize(window.innerWidth)
    };

    useEffect(function () {
        setStart(0)
        oldDataAfterSearchKey.current = 0;

    }, [submitCounter])

    useEffect(function () {
        setStart(0)
        setChangeOrderKey(0)
        setLength(djk)
        setRecordsFiltered(null)
        setOpen(null)
        setPages(null)

        setData([])
        setChangeOrder(false)
        setChangeOrderValue('')
        setSearchInput('')
        order.current = {};

    }, [refresh])


    useEffect(function () {
        var di = []


        Cols && Cols.forEach(function (v) {
            if (!di.includes(parseInt(v.responsivePriority))) {
                di.push(parseInt(v.responsivePriority))
            }
        })
        responsivePriority.current = di.sort(function (a, b) {
            return a - b;
        })

    }, [Cols])




    useEffect(function () {
        submit()
    }, [changeOrder, searchInput, length, start, submitCounter, dataTmp])


    useEffect(() => {


        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        handleResize()
        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    return (
        <Fragment>
            <div className="row mt-3">
                <div className="col-sm-12 col-md-3">
                    <div className="dataTables_length d-flex"
                    ><label className={'d-flex align-items-center'}>{ctx.translator('Show')}<select
                        value={length}
                        onChange={(e) => {
                            setLength(parseInt(e.target.value))
                        }}
                        className="custom-select custom-select-sm form-control form-control-sm">
                        {lengthMenu && lengthMenu.map(function (v, i) {
                            return <option key={'fdfsd5fd656trtrt56fdf' + i} value={v}>{v}</option>
                        })}
                    </select> {ctx.translator('entries')}</label></div>


                </div>
                <div className="col-sm-12 col-md-3">
                    {
                        recordsFiltered !== null &&
                        <p className={'form-label'}>{ctx.translator('Showing')} {start * length} {ctx.translator('To')} {(start * length) + length} ({ctx.translator('Total')}: {recordsFiltered.toLocaleString()})</p>
                    }
                </div>

                <div className="col-sm-12 col-md-6">
                    {searchBox === true &&
                        <div id="myTable_filter" className="dataTables_filter" style={{display: "block "}}>
                            <label>{ctx.translator('Search')}:<input
                                value={searchInput}
                                onChange={(e) => {
                                    setSearchInput(e.target.value)

                                }}
                                style={{display: "block"}} type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="myTable"/></label>
                        </div>}
                </div>

            </div>
            <div className={'row mt-3'}>


                {
                    loading === true && <h6 className={'text-center mt-3 fw-bold waiting-98  '} >

                         <p className={'w-100'} style={{
                             display:"flex",
                             justifyContent: "center",
                             alignItems: "center"
                         }}>
                             <ctx.ReactLoading type={'cubes'}
                                               color={'dark'}
                                               height={'3%'}
                                               width={'3%'}/>   &nbsp;
                             &nbsp;
                             {
                                 ctx.translator('Loading page Please Wait for a seconds')
                             }
                         </p>

                    </h6>
                }
                {loading === false && <table className={'mytableXX table table-striped'}>
                    <thead>
                    <tr>
                        {
                            Cols && Cols.map(function (e, index) {

                                if (index === 0) {
                                    colSpan.current = 0;
                                }
                                if (e['orderable'] !== undefined && e['orderable'] === true) {
                                    if (order.current[e['data']] === undefined) {
                                        order.current[e['data']] = 'asc'
                                    }

                                }
                                if (['userID', 'affID', 'currency'].includes(e['data'])) {
                                    e['width'] = '120';
                                }

                                if (['requestTime', 'actionTime'].includes(e['data'])) {
                                    e['width'] = '150';
                                }

                                const trtd = showTbl(e['responsivePriority'], e['data'] , index)

                                if (trtd === false) {
                                    colSpan.current++;
                                }
                                return (<th
                                    key={'dddf999999999999999999ererrtd' + index}
                                    style={{
                                        display: (trtd === false) ? 'table-cell' : 'none',
                                        width: ((e['width'] !== undefined && trtd === false) ? e['width'] + 'px' : 'auto')
                                    }}


                                    onClick={() => {
                                        if (order.current[e['data']] !== undefined) {
                                            if (order.current[e['data']] === 'asc') {
                                                order.current[e['data']] = 'desc';
                                            } else {
                                                order.current[e['data']] = 'asc';
                                            }

                                            setChangeOrderKey(index)
                                            setChangeOrderValue(e['data'])
                                            setChangeOrder(!changeOrder)
                                        }
                                    }}

                                >

                                    <div className={'d-flex'} style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: "pointer"
                                    }}>
                                        {e['title']}

                                        {order.current[e['data']] !== undefined && <Fragment>

                                            {
                                                order.current[e['data']] === 'asc' ?
                                                    <i style={{opacity: 0.5}} className="las la-sort-alpha-down-alt"></i> :
                                                    <i style={{opacity: 0.5}} className="las la-sort-alpha-up-alt"></i>
                                            }
                                        </Fragment>}
                                    </div>

                                </th>)
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>

                    {
                        data && data.map(function (v, o) {
                            const dk = o;
                            return (
                                <Fragment key={'ddsdfdfdfdf' + o}>

                                    <tr key={'ddsdfdfdfdf' + o} className={open === o ? 'dt-hasChild parent' : ''}>

                                        {
                                            Cols && Cols.map(function (e, index) {

                                                var tr = showTbl(e['responsivePriority'], e['data'],index)
                                                return (<td

                                                    style={{
                                                        display: tr === false ? 'table-cell' : 'none',
                                                        width: ((e['width'] !== undefined && tr === false) ? e['width'] + 'px' : 'auto')
                                                    }}
                                                    key={'ddd989df89d8f98d' + o + index}>
                                                    <div className={'d-flex'}>
                                                        {(index === 0 && limitCal.current.length > 0) && ((open === null || open !== dk) ?
                                                            <Fragment><i onClick={() => {

                                                                setOpen(dk)
                                                            }} className="mt-1 las la-plus-circle"></i>&nbsp;&nbsp;
                                                            </Fragment> :
                                                            <Fragment>
                                                                <i onClick={() => {
                                                                    setOpen(null)
                                                                }} className="mt-1  las la-minus-circle"></i>&nbsp;&nbsp;

                                                            </Fragment>)}


                                                        {e.render && e.render(v[e['data']], '', v, o)}
                                                    </div>

                                                </td>)
                                            })
                                        }
                                    </tr>

                                    {
                                        open === o && <tr className={'child  '}>


                                            <td className={'child'} colSpan={colSpan.current}>
                                                <ul className={'dtr-details p-0'}>
                                                    {
                                                        Cols && Cols.map(function (e, index) {

                                                            var tr = showTbl(e['responsivePriority'], e['data'],index)


                                                            return (<li style={{display: tr === true ? 'table-cell' : 'none'}}
                                                                        data-show={1}

                                                                        key={'dddf565df656d5f65d6f56dfd' + o + 'dfffdff' + index}>
                                                                <b>{e['title']} :</b>
                                                                {e.render && e.render(v[e['data']], 'display', v, o)}
                                                            </li>)
                                                        })
                                                    }
                                                </ul>

                                            </td>

                                        </tr>
                                    }
                                </Fragment>
                            )
                        })
                    }
                    </tbody>
                </table>}


            </div>
            <div className="row mt-2">
                <div className="col-sm-12 col-md-5">

                    {
                        recordsFiltered !== null &&
                        <p className={'form-label'}>{ctx.translator('Showing')} {start * length} {ctx.translator('To')} {(start * length) + length} ({ctx.translator('Total')}: {recordsFiltered.toLocaleString()})</p>
                    }
                </div>
                <div className="col-sm-12 col-md-7">
                    <nav className="pagination-style-4" id="myTable0555_paginate">
                        <ul className="pagination mb-0 pagination">
                            {start > 0 && <li onClick={() => {
                                setStart(0)
                            }} className="page-item first " id="myTable0555_first">
                                <div
                                    style={{cursor: 'pointer'}} className="page-link">{ctx.translator('First')}
                                </div>
                            </li>}
                            {start > 0 && <li
                                onClick={() => {
                                    if (start > 0) {
                                        setStart(start - 1)
                                    }
                                }}
                                className={"page-item previous " + (start > 0 ? '' : 'disabled')}
                                id="myTable0555_previous">
                                <span
                                    style={{cursor: 'pointer'}} className="page-link">{ctx.translator('Previous')}
                                </span>
                            </li>}
                            {paginations()}
                            {(pages === null || (start + 1) < pages) && data.length === length && <li
                                onClick={() => {
                                    setStart(start + 1)
                                }}
                                className="page-item next" id="myTable0555_next">
                                <span style={{cursor: 'pointer'}}
                                      className="page-link">{ctx.translator('Next')}
                                </span>
                            </li>}
                            {(pages === null || (start + 1) < pages) && pageCounter === false && <li
                                onClick={() => {
                                    setStart(pages - 1)
                                }}
                                className="page-item next">
                                <span style={{cursor: 'pointer'}}

                                      className="page-link">{ctx.translator('End')}
                                </span>
                            </li>}

                        </ul>
                    </nav>
                </div>
            </div>


        </Fragment>

    )
}
export default Index;
