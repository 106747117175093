import React, {Component} from 'react';

class ErrorHandler extends Component {
    state = {
        error: null,
        errorInfo: null,
        hasError: false,
        retryCount: 0
    };

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
            hasError: true
        });
    }

    handleRetry = () => {
        // Increment the retry count
        this.setState(prevState => ({
            retryCount: prevState.retryCount + 1,
            hasError: false // Reset the error state
        }));
    };


    render() {


        if ( this.state.hasError) {
            console.log(this.state.hasError)
            if(this.state.retryCount < 4){
                console.log('Go for Refresh Error')
                this.handleRetry()
                return '';
            }
            // You can render a fallback UI here
            return (
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: "center"
                }}>
                    <h1>Something went wrong</h1>
                    <button onClick={this.handleRetry}>Retry</button>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorHandler;
