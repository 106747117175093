import {NavLink} from "react-router-dom";

const Index = (props) => {

    function closeSi(){
        props.ctx.htmlReader.setAttribute('data-toggled', 'close');
        props.ctx.setClose(false)
    }
    function vts(v, o) {
        if (v.hasSub === false) return '';
        return (
            <ul className="slide-menu child1 active  "
                style={{display: props.ctx.activeMenu === v.value ? "block" : 'none'}}>
                {v.list && v.list.map(function (vd, oo) {
                    return (

                        <li onClick={() => {

                            props.ctx.setPage(vd.link)
                            props.ctx.setActiveMenu(v.value)
                            props.ctx.setActiveSubMenu(vd.value)
                            props.ctx.setMenuRefresh(!props.ctx.menuRefresh)
                            closeSi()
                        }} className="slide  " key={'dfdf' + o + oo}><NavLink
                            className={"side-menu__item  "  }
                            to={window.configX.root + vd.link}> {props.ctx.translator(vd.label)}  <span></span></NavLink>
                        </li>

                    )
                })}
            </ul>
        )
    }

    return (
        <aside className="app-sidebar sticky" id="sidebar">
            <div className="main-sidebar-header">
                <NavLink className="header-logo" to="/">


                    <img
                        src={props.ctx.logo} alt="logo" className="desktop-logo"/>
                    <img
                        src={props.ctx.logo} alt="logo" className="toggle-logo"/>
                    <img
                        src={props.ctx.logoWhite} alt="logo" className="desktop-dark"/>
                    <img
                        src={props.ctx.logoWhite} alt="logo" className="toggle-dark"/>
                    <img
                        src={props.ctx.logo} alt="logo" className="desktop-white"/>
                    <img
                        src={props.ctx.logo} alt="logo" className="toggle-white"/>
                </NavLink>

            </div>
            <div className="main-sidebar" id="sidebar-scroll" onMouseEnter={() => {


                if (props.ctx.close === true) {
                    if(props.ctx.closeMe === true){
                        props.ctx.htmlReader.setAttribute('data-icon-overlay', 'close')
                    }else{
                        props.ctx.htmlReader.setAttribute('data-icon-overlay', 'open')
                    }
                    props.ctx.setCloseMe(true)


                }

            }}
                 onMouseOut={() => {

                     if (props.ctx.close === true) {
                         if(props.ctx.closeMe === true){
                             props.ctx.htmlReader.setAttribute('data-icon-overlay', 'close')
                         }else{
                             props.ctx.htmlReader.setAttribute('data-icon-overlay', 'open')
                         }

                         props.ctx.setCloseMe(false)
                     }
                 }}

            >
                <div className="ScrollbarsCustom trackYVisible">
                    <div className="ScrollbarsCustom-Wrapper">
                        <div className="ScrollbarsCustom-Scroller">
                            <div className="ScrollbarsCustom-Content">
                                <nav className="main-menu-container nav nav-pills flex-column sub-open">
                                    <div className="slide-left" id="slide-left">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24"
                                             viewBox="0 0 24 24">
                                            <path
                                                d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                                        </svg>
                                    </div>


                                    <ul className="main-menu">

                                        {props.ctx.setting && props.ctx.setting.menus.map(function (v, o) {

                                            if(v.visible !== true){

                                                if(props.ctx.checkPremision(v.visible ) === false){
                                                 return  '';
                                                }
                                            }
                                            return (
                                                <li key={'d' + o}
                                                    className={"slide " + (v.hasSub === true ? ' has-sub ' : '') +
                                                        (props.ctx.activeMenu === v.value ? ' open ' : ' ')}
                                                >


                                                    {v.hasSub === false && <NavLink onClick={()=>{

                                                        props.ctx.setPage(v.link)
                                                        props.ctx.setMenuRefresh(!props.ctx.menuRefresh)

                                                        closeSi()

                                                    }} to={window.configX.root + v.link}
                                                                                    className={"side-menu__item"  }>
                                                        <i className={v.icon + " side-menu__icon"}></i>
                                                        <span className="side-menu__label">
                                                            {props.ctx.translator(v.label)}
                                                             <span
                                                                className=" badge bg-success-transparent ms-2 fade-in-me" id={v.value+'-id'} style={{display:v.tx === '' ? 'none' : 'inline'}}> {v.tx}  </span>
                                                        </span>

                                                    </NavLink>}

                                                </li>
                                            )
                                        })}


                                    </ul>


                                    <div className="slide-right" id="slide-right">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24"
                                             viewBox="0 0 24 24">
                                            <path
                                                d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                                        </svg>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </aside>
    )
}
export default Index
