import ReactLoading from "react-loading";
import React from "react";

const MyLoading = ({d = 0}) => {

    return (
        <div className={'w-100'} style={{
            height:'100vh',
            zIndex:9999,
            position:"absolute",
            top: '0%',
            left: '0%',
            background: "rgb(135 135 135 / 32%)"
        }}>
            <div style={{position: "fixed", top: '35%',  left: '0%',
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
                ,zIndex:999999, width: '100%'}}>
                <ReactLoading type={'bubbles'}
                              color={'dark'}
                              height={'10%'}
                              width={'10%'}/>
            </div>
        </div>

    )
}

export  default MyLoading;
