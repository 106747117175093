import React from "react";
import {DataContextPaypal} from "./store/data-context";
import Index from "./components/Index";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

function App() {
    return (
        <DataContextPaypal>
            <Index/>
        </DataContextPaypal>
    );
}

export default App;
