import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorHandler from "./errorHandler";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorHandler>
        <BrowserRouter>
            <ToastContainer/>
            <App/>
        </BrowserRouter>
    </ErrorHandler>
);


reportWebVitals();
