import React, {Fragment, useEffect, useRef, useState} from "react";
import logo from '../ynex-ts/preview/assets/logo.png';
import logoWhite from '../ynex-ts/preview/assets/logo-white.png';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Swal from 'sweetalert2'
import Loading from '../components/MyLoading';
import {NavLink} from "react-router-dom";
import lang from './translate.json';
import moment from "moment";
import {toast} from "react-toastify";
import momentJalaali from 'moment-jalaali';
import DatePicker from "react-datepicker2";
import copy from "copy-to-clipboard";
import mp3 from "../ynex-ts/send-.mp3";
import btc from "../ynex-ts/icon/btc.png";
import btt from "../ynex-ts/icon/btt.png";
import doge from "../ynex-ts/icon/doge.png";
import eth from "../ynex-ts/icon/eth.png";
import jst from "../ynex-ts/icon/jst.png";
import ltc from "../ynex-ts/icon/ltc.png";
import pm from "../ynex-ts/icon/pm.png";
import sun from "../ynex-ts/icon/sun.png";
import trx from "../ynex-ts/icon/trx.png";
import usdt from "../ynex-ts/icon/usdt.png";
import win from "../ynex-ts/icon/win.png";
import ansar from "../ynex-ts/icon/ansar.png";
import ayandeh from "../ynex-ts/icon/ayandeh.png";
import bankmehriran from "../ynex-ts/icon/bank-mehr-iran.png";
import day from "../ynex-ts/icon/day.png";
import defaultIcon from "../ynex-ts/icon/default.png";
import eghtesad from "../ynex-ts/icon/eghtesad.png";
import etebarinoor from "../ynex-ts/icon/etebarinoor.png";
import etebarisamen from "../ynex-ts/icon/etebarisamen.png";
import etebaritosee from "../ynex-ts/icon/etebaritosee.png";
import gardeshgari from "../ynex-ts/icon/gardeshgari.png";
import ghavamin from "../ynex-ts/icon/ghavamin.png";
import hekmat from "../ynex-ts/icon/hekmat.png";
import iranvenezoela from "../ynex-ts/icon/iranvenezoela.png";
import iranzamin from "../ynex-ts/icon/iranzamin.png";
import karafarin from "../ynex-ts/icon/karafarin.png";
import keshavarsi from "../ynex-ts/icon/keshavarsi.png";
import khavarmiyaneh from "../ynex-ts/icon/khavarmiyaneh.png";
import kosar from "../ynex-ts/icon/kosar.png";
import markazi from "../ynex-ts/icon/markazi.png";
import maskan from "../ynex-ts/icon/maskan.png";
import mehreqtesad from "../ynex-ts/icon/mehreqtesad.png";
import melal from "../ynex-ts/icon/melal.png";
import meli from "../ynex-ts/icon/meli.png";
import mellat from "../ynex-ts/icon/mellat.png";
import parsian from "../ynex-ts/icon/parsian.png";
import pasargad from "../ynex-ts/icon/pasargad.png";
import postbank from "../ynex-ts/icon/postbank.png";
import refah from "../ynex-ts/icon/refah.png";
import resalat from "../ynex-ts/icon/resalat.png";
import saderat from "../ynex-ts/icon/saderat.png";
import saman from "../ynex-ts/icon/saman.png";
import sanatmadan from "../ynex-ts/icon/sanatmadan.png";
import sarmaye from "../ynex-ts/icon/sarmaye.png";
import sepah from "../ynex-ts/icon/sepah.png";
import shahr from "../ynex-ts/icon/shahr.png";
import sina from "../ynex-ts/icon/sina.png";
import tejarat from "../ynex-ts/icon/tejarat.png";
import toseesaderat from "../ynex-ts/icon/toseesaderat.png";
import tosehe from "../ynex-ts/icon/tosehe.png";
import pool1 from "../ynex-ts/p1.png";
import pool2 from "../ynex-ts/p2.png";
import pool3 from "../ynex-ts/p3.png";


import Dropdown from "react-bootstrap/Dropdown";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Progress,
    Row,
    Table
} from "reactstrap";
import Select from "react-select";
import DataTableHandler from "../components/DataTable";
import 'moment-timezone';
import ReactLoading from "react-loading";

const DataContext = React.createContext();
export const DataContextPaypal = (props) => {

    const htmlReader = document.getElementsByTagName('html')[0];

    if (localStorage.getItem('data-menu-styles') === null) {
        localStorage.setItem('data-menu-styles', 'light')
    }
    if (localStorage.getItem('data-theme-mode') === null) {
        localStorage.setItem('data-theme-mode', 'light')
    }
    if (localStorage.getItem('data-header-styles') === null) {
        localStorage.setItem('data-header-styles', 'light')
    }
    if (localStorage.getItem('calender-paypal') === null) {
        localStorage.setItem('calender-paypal', 'georgian')
    }

    if (localStorage.getItem('lang-paypal') === null) {
        localStorage.setItem('lang-paypal', window.configX.defLang)
    }
    if (localStorage.getItem('sound-support') === null) {
        localStorage.setItem('sound-support', '1')
    }
    if (localStorage.getItem('timezone-paypal') === null) {
        localStorage.setItem('timezone-paypal', '')
    }
    if (localStorage.getItem('chat-support') === null) {
        localStorage.setItem('chat-support', '0')
    }

    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') === null ? '' : localStorage.getItem('authToken'));
    const [refresh, setRefresh] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');
    const [activeSubMenu, setActiveSubMenu] = useState('');
    const [closeMe, setCloseMe] = useState(false);
    const [close, setClose] = useState(false);
    const [balance, setBalance] = useState({"commissionBalance":0,"accountBalance":0,"updateTime":1711534607,"username":"-","currency":"CHP"});
    const [myTimeZone, setMyTimeZone] = useState(localStorage.getItem('timezone-paypal') === '' ? null : {
        value: localStorage.getItem('timezone-paypal'),
        label: localStorage.getItem('timezone-paypal')
    });



    const ws = useRef();
    const pnum = useRef(0);
    const setting = useRef({
        'permission': localStorage.getItem('permissionPay') === null ? false : JSON.parse(localStorage.getItem('permissionPay')),
        'data-theme-mode': localStorage.getItem('data-theme-mode'),
        'data-menu-styles': localStorage.getItem('data-menu-styles'),
        'data-header-styles': localStorage.getItem('data-header-styles'),
        'calender': localStorage.getItem('calender-paypal'),
        'lang': localStorage.getItem('lang-paypal'),
        'errorSocket': 0,
        "toks": {

            'pm': [pm, 'Perfect money'],//
            'usdt': [usdt, 'Theter(USDT)'],//
            'btc': [btc, 'Bitcoin(BTC)'],//
            'eth': [eth, 'Ethereum(ETH)'],//
            'ltc': [ltc, 'Litecoin(LTC)'],//
            'doge': [doge, 'Dogecoin(DOGE)'],
            'trx': [trx, 'Tron(TRX)'],//
            'sun': [sun, 'Sun(SUN)'],
            'win': [win, 'WinkLink(WIN)'],
            'jst': [jst, 'Just(JST)'],
            'btt': [btt, 'Bittorent(BTT)'],
        },
        'timeZones': [
            {"value": "Africa/Abidjan", "label": "Africa/Abidjan"},
            {"value": "Africa/Accra", "label": "Africa/Accra"},
            {"value": "Africa/Addis_Ababa", "label": "Africa/Addis_Ababa"},
            {"value": "Africa/Algiers", "label": "Africa/Algiers"},
            {"value": "Africa/Asmara", "label": "Africa/Asmara"},
            {"value": "Africa/Bamako", "label": "Africa/Bamako"},
            {"value": "Africa/Bangui", "label": "Africa/Bangui"},
            {"value": "Africa/Banjul", "label": "Africa/Banjul"},
            {"value": "Africa/Bissau", "label": "Africa/Bissau"},
            {"value": "Africa/Blantyre", "label": "Africa/Blantyre"},
            {"value": "Africa/Brazzaville", "label": "Africa/Brazzaville"},
            {"value": "Africa/Bujumbura", "label": "Africa/Bujumbura"},
            {"value": "Africa/Cairo", "label": "Africa/Cairo"},
            {"value": "Africa/Casablanca", "label": "Africa/Casablanca"},
            {"value": "Africa/Ceuta", "label": "Africa/Ceuta"},
            {"value": "Africa/Conakry", "label": "Africa/Conakry"},
            {"value": "Africa/Dakar", "label": "Africa/Dakar"},
            {"value": "Africa/Dar_es_Salaam", "label": "Africa/Dar_es_Salaam"},
            {"value": "Africa/Djibouti", "label": "Africa/Djibouti"},
            {"value": "Africa/Douala", "label": "Africa/Douala"},
            {"value": "Africa/El_Aaiun", "label": "Africa/El_Aaiun"},
            {"value": "Africa/Freetown", "label": "Africa/Freetown"},
            {"value": "Africa/Gaborone", "label": "Africa/Gaborone"},
            {"value": "Africa/Harare", "label": "Africa/Harare"},
            {"value": "Africa/Johannesburg", "label": "Africa/Johannesburg"},
            {"value": "Africa/Juba", "label": "Africa/Juba"},
            {"value": "Africa/Kampala", "label": "Africa/Kampala"},
            {"value": "Africa/Khartoum", "label": "Africa/Khartoum"},
            {"value": "Africa/Kigali", "label": "Africa/Kigali"},
            {"value": "Africa/Kinshasa", "label": "Africa/Kinshasa"},
            {"value": "Africa/Lagos", "label": "Africa/Lagos"},
            {"value": "Africa/Libreville", "label": "Africa/Libreville"},
            {"value": "Africa/Lome", "label": "Africa/Lome"},
            {"value": "Africa/Luanda", "label": "Africa/Luanda"},
            {"value": "Africa/Lubumbashi", "label": "Africa/Lubumbashi"},
            {"value": "Africa/Lusaka", "label": "Africa/Lusaka"},
            {"value": "Africa/Malabo", "label": "Africa/Malabo"},
            {"value": "Africa/Maputo", "label": "Africa/Maputo"},
            {"value": "Africa/Maseru", "label": "Africa/Maseru"},
            {"value": "Africa/Mbabane", "label": "Africa/Mbabane"},
            {"value": "Africa/Mogadishu", "label": "Africa/Mogadishu"},
            {"value": "Africa/Monrovia", "label": "Africa/Monrovia"},
            {"value": "Africa/Nairobi", "label": "Africa/Nairobi"},
            {"value": "Africa/Ndjamena", "label": "Africa/Ndjamena"},
            {"value": "Africa/Niamey", "label": "Africa/Niamey"},
            {"value": "Africa/Nouakchott", "label": "Africa/Nouakchott"},
            {"value": "Africa/Ouagadougou", "label": "Africa/Ouagadougou"},
            {"value": "Africa/Porto-Novo", "label": "Africa/Porto-Novo"},
            {"value": "Africa/Sao_Tome", "label": "Africa/Sao_Tome"},
            {"value": "Africa/Tripoli", "label": "Africa/Tripoli"},
            {"value": "Africa/Tunis", "label": "Africa/Tunis"},
            {"value": "Africa/Windhoek", "label": "Africa/Windhoek"},
            {"value": "America/Adak", "label": "America/Adak"},
            {"value": "America/Anchorage", "label": "America/Anchorage"},
            {"value": "America/Anguilla", "label": "America/Anguilla"},
            {"value": "America/Antigua", "label": "America/Antigua"},
            {"value": "America/Araguaina", "label": "America/Araguaina"},
            {"value": "America/Argentina/Buenos_Aires", "label": "America/Argentina/Buenos_Aires"},
            {"value": "America/Argentina/Catamarca", "label": "America/Argentina/Catamarca"},
            {"value": "America/Argentina/Cordoba", "label": "America/Argentina/Cordoba"},
            {"value": "America/Argentina/Jujuy", "label": "America/Argentina/Jujuy"},
            {"value": "America/Argentina/La_Rioja", "label": "America/Argentina/La_Rioja"},
            {"value": "America/Argentina/Mendoza", "label": "America/Argentina/Mendoza"},
            {"value": "America/Argentina/Rio_Gallegos", "label": "America/Argentina/Rio_Gallegos"},
            {"value": "America/Argentina/Salta", "label": "America/Argentina/Salta"},
            {"value": "America/Argentina/San_Juan", "label": "America/Argentina/San_Juan"},
            {"value": "America/Argentina/San_Luis", "label": "America/Argentina/San_Luis"},
            {"value": "America/Argentina/Tucuman", "label": "America/Argentina/Tucuman"},
            {"value": "America/Argentina/Ushuaia", "label": "America/Argentina/Ushuaia"},
            {"value": "America/Aruba", "label": "America/Aruba"},
            {"value": "America/Asuncion", "label": "America/Asuncion"},
            {"value": "America/Atikokan", "label": "America/Atikokan"},
            {"value": "America/Bahia", "label": "America/Bahia"},
            {"value": "America/Bahia_Banderas", "label": "America/Bahia_Banderas"},
            {"value": "America/Barbados", "label": "America/Barbados"},
            {"value": "America/Belem", "label": "America/Belem"},
            {"value": "America/Belize", "label": "America/Belize"},
            {"value": "America/Blanc-Sablon", "label": "America/Blanc-Sablon"},
            {"value": "America/Boa_Vista", "label": "America/Boa_Vista"},
            {"value": "America/Bogota", "label": "America/Bogota"},
            {"value": "America/Boise", "label": "America/Boise"},
            {"value": "America/Cambridge_Bay", "label": "America/Cambridge_Bay"},
            {"value": "America/Campo_Grande", "label": "America/Campo_Grande"},
            {"value": "America/Cancun", "label": "America/Cancun"},
            {"value": "America/Caracas", "label": "America/Caracas"},
            {"value": "America/Cayenne", "label": "America/Cayenne"},
            {"value": "America/Cayman", "label": "America/Cayman"},
            {"value": "America/Chicago", "label": "America/Chicago"},
            {"value": "America/Chihuahua", "label": "America/Chihuahua"},
            {"value": "America/Costa_Rica", "label": "America/Costa_Rica"},
            {"value": "America/Creston", "label": "America/Creston"},
            {"value": "America/Cuiaba", "label": "America/Cuiaba"},
            {"value": "America/Curacao", "label": "America/Curacao"},
            {"value": "America/Danmarkshavn", "label": "America/Danmarkshavn"},
            {"value": "America/Dawson", "label": "America/Dawson"},
            {"value": "America/Dawson_Creek", "label": "America/Dawson_Creek"},
            {"value": "America/Denver", "label": "America/Denver"},
            {"value": "America/Detroit", "label": "America/Detroit"},
            {"value": "America/Dominica", "label": "America/Dominica"},
            {"value": "America/Edmonton", "label": "America/Edmonton"},
            {"value": "America/Eirunepe", "label": "America/Eirunepe"},
            {"value": "America/El_Salvador", "label": "America/El_Salvador"},
            {"value": "America/Fort_Nelson", "label": "America/Fort_Nelson"},
            {"value": "America/Fortaleza", "label": "America/Fortaleza"},
            {"value": "America/Glace_Bay", "label": "America/Glace_Bay"},
            {"value": "America/Goose_Bay", "label": "America/Goose_Bay"},
            {"value": "America/Grand_Turk", "label": "America/Grand_Turk"},
            {"value": "America/Grenada", "label": "America/Grenada"},
            {"value": "America/Guadeloupe", "label": "America/Guadeloupe"},
            {"value": "America/Guatemala", "label": "America/Guatemala"},
            {"value": "America/Guayaquil", "label": "America/Guayaquil"},
            {"value": "America/Guyana", "label": "America/Guyana"},
            {"value": "America/Halifax", "label": "America/Halifax"},
            {"value": "America/Havana", "label": "America/Havana"},
            {"value": "America/Hermosillo", "label": "America/Hermosillo"},
            {"value": "America/Indiana/Indianapolis", "label": "America/Indiana/Indianapolis"},
            {"value": "America/Indiana/Knox", "label": "America/Indiana/Knox"},
            {"value": "America/Indiana/Marengo", "label": "America/Indiana/Marengo"},
            {"value": "America/Indiana/Petersburg", "label": "America/Indiana/Petersburg"},
            {"value": "America/Indiana/Tell_City", "label": "America/Indiana/Tell_City"},
            {"value": "America/Indiana/Vevay", "label": "America/Indiana/Vevay"},
            {"value": "America/Indiana/Vincennes", "label": "America/Indiana/Vincennes"},
            {"value": "America/Indiana/Winamac", "label": "America/Indiana/Winamac"},
            {"value": "America/Inuvik", "label": "America/Inuvik"},
            {"value": "America/Iqaluit", "label": "America/Iqaluit"},
            {"value": "America/Jamaica", "label": "America/Jamaica"},
            {"value": "America/Juneau", "label": "America/Juneau"},
            {"value": "America/Kentucky/Louisville", "label": "America/Kentucky/Louisville"},
            {"value": "America/Kentucky/Monticello", "label": "America/Kentucky/Monticello"},
            {"value": "America/Kralendijk", "label": "America/Kralendijk"},
            {"value": "America/La_Paz", "label": "America/La_Paz"},
            {"value": "America/Lima", "label": "America/Lima"},
            {"value": "America/Los_Angeles", "label": "America/Los_Angeles"},
            {"value": "America/Lower_Princes", "label": "America/Lower_Princes"},
            {"value": "America/Maceio", "label": "America/Maceio"},
            {"value": "America/Managua", "label": "America/Managua"},
            {"value": "America/Manaus", "label": "America/Manaus"},
            {"value": "America/Marigot", "label": "America/Marigot"},
            {"value": "America/Martinique", "label": "America/Martinique"},
            {"value": "America/Matamoros", "label": "America/Matamoros"},
            {"value": "America/Mazatlan", "label": "America/Mazatlan"},
            {"value": "America/Menominee", "label": "America/Menominee"},
            {"value": "America/Merida", "label": "America/Merida"},
            {"value": "America/Metlakatla", "label": "America/Metlakatla"},
            {"value": "America/Mexico_City", "label": "America/Mexico_City"},
            {"value": "America/Miquelon", "label": "America/Miquelon"},
            {"value": "America/Moncton", "label": "America/Moncton"},
            {"value": "America/Monterrey", "label": "America/Monterrey"},
            {"value": "America/Montevideo", "label": "America/Montevideo"},
            {"value": "America/Montserrat", "label": "America/Montserrat"},
            {"value": "America/Nassau", "label": "America/Nassau"},
            {"value": "America/New_York", "label": "America/New_York"},
            {"value": "America/Nipigon", "label": "America/Nipigon"},
            {"value": "America/Nome", "label": "America/Nome"},
            {"value": "America/Noronha", "label": "America/Noronha"},
            {"value": "America/North_Dakota/Beulah", "label": "America/North_Dakota/Beulah"},
            {"value": "America/North_Dakota/Center", "label": "America/North_Dakota/Center"},
            {"value": "America/North_Dakota/New_Salem", "label": "America/North_Dakota/New_Salem"},
            {"value": "America/Nuuk", "label": "America/Nuuk"},
            {"value": "America/Ojinaga", "label": "America/Ojinaga"},
            {"value": "America/Panama", "label": "America/Panama"},
            {"value": "America/Pangnirtung", "label": "America/Pangnirtung"},
            {"value": "America/Paramaribo", "label": "America/Paramaribo"},
            {"value": "America/Phoenix", "label": "America/Phoenix"},
            {"value": "America/Port-au-Prince", "label": "America/Port-au-Prince"},
            {"value": "America/Port_of_Spain", "label": "America/Port_of_Spain"},
            {"value": "America/Porto_Velho", "label": "America/Porto_Velho"},
            {"value": "America/Puerto_Rico", "label": "America/Puerto_Rico"},
            {"value": "America/Punta_Arenas", "label": "America/Punta_Arenas"},
            {"value": "America/Rainy_River", "label": "America/Rainy_River"},
            {"value": "America/Rankin_Inlet", "label": "America/Rankin_Inlet"},
            {"value": "America/Recife", "label": "America/Recife"},
            {"value": "America/Regina", "label": "America/Regina"},
            {"value": "America/Resolute", "label": "America/Resolute"},
            {"value": "America/Rio_Branco", "label": "America/Rio_Branco"},
            {"value": "America/Santarem", "label": "America/Santarem"},
            {"value": "America/Santiago", "label": "America/Santiago"},
            {"value": "America/Santo_Domingo", "label": "America/Santo_Domingo"},
            {"value": "America/Sao_Paulo", "label": "America/Sao_Paulo"},
            {"value": "America/Scoresbysund", "label": "America/Scoresbysund"},
            {"value": "America/Sitka", "label": "America/Sitka"},
            {"value": "America/St_Barthelemy", "label": "America/St_Barthelemy"},
            {"value": "America/St_Johns", "label": "America/St_Johns"},
            {"value": "America/St_Kitts", "label": "America/St_Kitts"},
            {"value": "America/St_Lucia", "label": "America/St_Lucia"},
            {"value": "America/St_Thomas", "label": "America/St_Thomas"},
            {"value": "America/St_Vincent", "label": "America/St_Vincent"},
            {"value": "America/Swift_Current", "label": "America/Swift_Current"},
            {"value": "America/Tegucigalpa", "label": "America/Tegucigalpa"},
            {"value": "America/Thule", "label": "America/Thule"},
            {"value": "America/Thunder_Bay", "label": "America/Thunder_Bay"},
            {"value": "America/Tijuana", "label": "America/Tijuana"},
            {"value": "America/Toronto", "label": "America/Toronto"},
            {"value": "America/Tortola", "label": "America/Tortola"},
            {"value": "America/Vancouver", "label": "America/Vancouver"},
            {"value": "America/Whitehorse", "label": "America/Whitehorse"},
            {"value": "America/Winnipeg", "label": "America/Winnipeg"},
            {"value": "America/Yakutat", "label": "America/Yakutat"},
            {"value": "America/Yellowknife", "label": "America/Yellowknife"},
            {"value": "Antarctica/Casey", "label": "Antarctica/Casey"},
            {"value": "Antarctica/Davis", "label": "Antarctica/Davis"},
            {"value": "Antarctica/DumontDUrville", "label": "Antarctica/DumontDUrville"},
            {"value": "Antarctica/Macquarie", "label": "Antarctica/Macquarie"},
            {"value": "Antarctica/Mawson", "label": "Antarctica/Mawson"},
            {"value": "Antarctica/McMurdo", "label": "Antarctica/McMurdo"},
            {"value": "Antarctica/Palmer", "label": "Antarctica/Palmer"},
            {"value": "Antarctica/Rothera", "label": "Antarctica/Rothera"},
            {"value": "Antarctica/Syowa", "label": "Antarctica/Syowa"},
            {"value": "Antarctica/Troll", "label": "Antarctica/Troll"},
            {"value": "Antarctica/Vostok", "label": "Antarctica/Vostok"},
            {"value": "Arctic/Longyearbyen", "label": "Arctic/Longyearbyen"},
            {"value": "Asia/Aden", "label": "Asia/Aden"},
            {"value": "Asia/Almaty", "label": "Asia/Almaty"},
            {"value": "Asia/Amman", "label": "Asia/Amman"},
            {"value": "Asia/Anadyr", "label": "Asia/Anadyr"},
            {"value": "Asia/Aqtau", "label": "Asia/Aqtau"},
            {"value": "Asia/Aqtobe", "label": "Asia/Aqtobe"},
            {"value": "Asia/Ashgabat", "label": "Asia/Ashgabat"},
            {"value": "Asia/Atyrau", "label": "Asia/Atyrau"},
            {"value": "Asia/Baghdad", "label": "Asia/Baghdad"},
            {"value": "Asia/Bahrain", "label": "Asia/Bahrain"},
            {"value": "Asia/Baku", "label": "Asia/Baku"},
            {"value": "Asia/Bangkok", "label": "Asia/Bangkok"},
            {"value": "Asia/Barnaul", "label": "Asia/Barnaul"},
            {"value": "Asia/Beirut", "label": "Asia/Beirut"},
            {"value": "Asia/Bishkek", "label": "Asia/Bishkek"},
            {"value": "Asia/Brunei", "label": "Asia/Brunei"},
            {"value": "Asia/Chita", "label": "Asia/Chita"},
            {"value": "Asia/Choibalsan", "label": "Asia/Choibalsan"},
            {"value": "Asia/Colombo", "label": "Asia/Colombo"},
            {"value": "Asia/Damascus", "label": "Asia/Damascus"},
            {"value": "Asia/Dhaka", "label": "Asia/Dhaka"},
            {"value": "Asia/Dili", "label": "Asia/Dili"},
            {"value": "Asia/Dubai", "label": "Asia/Dubai"},
            {"value": "Asia/Dushanbe", "label": "Asia/Dushanbe"},
            {"value": "Asia/Famagusta", "label": "Asia/Famagusta"},
            {"value": "Asia/Gaza", "label": "Asia/Gaza"},
            {"value": "Asia/Hebron", "label": "Asia/Hebron"},
            {"value": "Asia/Ho_Chi_Minh", "label": "Asia/Ho_Chi_Minh"},
            {"value": "Asia/Hong_Kong", "label": "Asia/Hong_Kong"},
            {"value": "Asia/Hovd", "label": "Asia/Hovd"},
            {"value": "Asia/Irkutsk", "label": "Asia/Irkutsk"},
            {"value": "Asia/Jakarta", "label": "Asia/Jakarta"},
            {"value": "Asia/Jayapura", "label": "Asia/Jayapura"},
            {"value": "Asia/Jerusalem", "label": "Asia/Jerusalem"},
            {"value": "Asia/Kabul", "label": "Asia/Kabul"},
            {"value": "Asia/Kamchatka", "label": "Asia/Kamchatka"},
            {"value": "Asia/Karachi", "label": "Asia/Karachi"},
            {"value": "Asia/Kathmandu", "label": "Asia/Kathmandu"},
            {"value": "Asia/Khandyga", "label": "Asia/Khandyga"},
            {"value": "Asia/Kolkata", "label": "Asia/Kolkata"},
            {"value": "Asia/Krasnoyarsk", "label": "Asia/Krasnoyarsk"},
            {"value": "Asia/Kuala_Lumpur", "label": "Asia/Kuala_Lumpur"},
            {"value": "Asia/Kuching", "label": "Asia/Kuching"},
            {"value": "Asia/Kuwait", "label": "Asia/Kuwait"},
            {"value": "Asia/Macau", "label": "Asia/Macau"},
            {"value": "Asia/Magadan", "label": "Asia/Magadan"},
            {"value": "Asia/Makassar", "label": "Asia/Makassar"},
            {"value": "Asia/Manila", "label": "Asia/Manila"},
            {"value": "Asia/Muscat", "label": "Asia/Muscat"},
            {"value": "Asia/Nicosia", "label": "Asia/Nicosia"},
            {"value": "Asia/Novokuznetsk", "label": "Asia/Novokuznetsk"},
            {"value": "Asia/Novosibirsk", "label": "Asia/Novosibirsk"},
            {"value": "Asia/Omsk", "label": "Asia/Omsk"},
            {"value": "Asia/Oral", "label": "Asia/Oral"},
            {"value": "Asia/Phnom_Penh", "label": "Asia/Phnom_Penh"},
            {"value": "Asia/Pontianak", "label": "Asia/Pontianak"},
            {"value": "Asia/Pyongyang", "label": "Asia/Pyongyang"},
            {"value": "Asia/Qatar", "label": "Asia/Qatar"},
            {"value": "Asia/Qostanay", "label": "Asia/Qostanay"},
            {"value": "Asia/Qyzylorda", "label": "Asia/Qyzylorda"},
            {"value": "Asia/Riyadh", "label": "Asia/Riyadh"},
            {"value": "Asia/Sakhalin", "label": "Asia/Sakhalin"},
            {"value": "Asia/Samarkand", "label": "Asia/Samarkand"},
            {"value": "Asia/Seoul", "label": "Asia/Seoul"},
            {"value": "Asia/Shanghai", "label": "Asia/Shanghai"},
            {"value": "Asia/Singapore", "label": "Asia/Singapore"},
            {"value": "Asia/Srednekolymsk", "label": "Asia/Srednekolymsk"},
            {"value": "Asia/Taipei", "label": "Asia/Taipei"},
            {"value": "Asia/Tashkent", "label": "Asia/Tashkent"},
            {"value": "Asia/Tbilisi", "label": "Asia/Tbilisi"},
            {"value": "Asia/Tehran", "label": "Asia/Tehran"},
            {"value": "Asia/Thimphu", "label": "Asia/Thimphu"},
            {"value": "Asia/Tokyo", "label": "Asia/Tokyo"},
            {"value": "Asia/Tomsk", "label": "Asia/Tomsk"},
            {"value": "Asia/Ulaanbaatar", "label": "Asia/Ulaanbaatar"},
            {"value": "Asia/Urumqi", "label": "Asia/Urumqi"},
            {"value": "Asia/Ust-Nera", "label": "Asia/Ust-Nera"},
            {"value": "Asia/Vientiane", "label": "Asia/Vientiane"},
            {"value": "Asia/Vladivostok", "label": "Asia/Vladivostok"},
            {"value": "Asia/Yakutsk", "label": "Asia/Yakutsk"},
            {"value": "Asia/Yangon", "label": "Asia/Yangon"},
            {"value": "Asia/Yekaterinburg", "label": "Asia/Yekaterinburg"},
            {"value": "Asia/Yerevan", "label": "Asia/Yerevan"},
            {"value": "Atlantic/Azores", "label": "Atlantic/Azores"},
            {"value": "Atlantic/Bermuda", "label": "Atlantic/Bermuda"},
            {"value": "Atlantic/Canary", "label": "Atlantic/Canary"},
            {"value": "Atlantic/Cape_Verde", "label": "Atlantic/Cape_Verde"},
            {"value": "Atlantic/Faroe", "label": "Atlantic/Faroe"},
            {"value": "Atlantic/Madeira", "label": "Atlantic/Madeira"},
            {"value": "Atlantic/Reykjavik", "label": "Atlantic/Reykjavik"},
            {"value": "Atlantic/South_Georgia", "label": "Atlantic/South_Georgia"},
            {"value": "Atlantic/St_Helena", "label": "Atlantic/St_Helena"},
            {"value": "Atlantic/Stanley", "label": "Atlantic/Stanley"},
            {"value": "Australia/Adelaide", "label": "Australia/Adelaide"},
            {"value": "Australia/Brisbane", "label": "Australia/Brisbane"},
            {"value": "Australia/Broken_Hill", "label": "Australia/Broken_Hill"},
            {"value": "Australia/Darwin", "label": "Australia/Darwin"},
            {"value": "Australia/Eucla", "label": "Australia/Eucla"},
            {"value": "Australia/Hobart", "label": "Australia/Hobart"},
            {"value": "Australia/Lindeman", "label": "Australia/Lindeman"},
            {"value": "Australia/Lord_Howe", "label": "Australia/Lord_Howe"},
            {"value": "Australia/Melbourne", "label": "Australia/Melbourne"},
            {"value": "Australia/Perth", "label": "Australia/Perth"},
            {"value": "Australia/Sydney", "label": "Australia/Sydney"},
            {"value": "Europe/Amsterdam", "label": "Europe/Amsterdam"},
            {"value": "Europe/Andorra", "label": "Europe/Andorra"},
            {"value": "Europe/Astrakhan", "label": "Europe/Astrakhan"},
            {"value": "Europe/Athens", "label": "Europe/Athens"},
            {"value": "Europe/Belgrade", "label": "Europe/Belgrade"},
            {"value": "Europe/Berlin", "label": "Europe/Berlin"},
            {"value": "Europe/Bratislava", "label": "Europe/Bratislava"},
            {"value": "Europe/Brussels", "label": "Europe/Brussels"},
            {"value": "Europe/Bucharest", "label": "Europe/Bucharest"},
            {"value": "Europe/Budapest", "label": "Europe/Budapest"},
            {"value": "Europe/Busingen", "label": "Europe/Busingen"},
            {"value": "Europe/Chisinau", "label": "Europe/Chisinau"},
            {"value": "Europe/Copenhagen", "label": "Europe/Copenhagen"},
            {"value": "Europe/Dublin", "label": "Europe/Dublin"},
            {"value": "Europe/Gibraltar", "label": "Europe/Gibraltar"},
            {"value": "Europe/Guernsey", "label": "Europe/Guernsey"},
            {"value": "Europe/Helsinki", "label": "Europe/Helsinki"},
            {"value": "Europe/Isle_of_Man", "label": "Europe/Isle_of_Man"},
            {"value": "Europe/Istanbul", "label": "Europe/Istanbul"},
            {"value": "Europe/Jersey", "label": "Europe/Jersey"},
            {"value": "Europe/Kaliningrad", "label": "Europe/Kaliningrad"},
            {"value": "Europe/Kiev", "label": "Europe/Kiev"},
            {"value": "Europe/Kirov", "label": "Europe/Kirov"},
            {"value": "Europe/Lisbon", "label": "Europe/Lisbon"},
            {"value": "Europe/Ljubljana", "label": "Europe/Ljubljana"},
            {"value": "Europe/London", "label": "Europe/London"},
            {"value": "Europe/Luxembourg", "label": "Europe/Luxembourg"},
            {"value": "Europe/Madrid", "label": "Europe/Madrid"},
            {"value": "Europe/Malta", "label": "Europe/Malta"},
            {"value": "Europe/Mariehamn", "label": "Europe/Mariehamn"},
            {"value": "Europe/Minsk", "label": "Europe/Minsk"},
            {"value": "Europe/Monaco", "label": "Europe/Monaco"},
            {"value": "Europe/Moscow", "label": "Europe/Moscow"},
            {"value": "Europe/Oslo", "label": "Europe/Oslo"},
            {"value": "Europe/Paris", "label": "Europe/Paris"},
            {"value": "Europe/Podgorica", "label": "Europe/Podgorica"},
            {"value": "Europe/Prague", "label": "Europe/Prague"},
            {"value": "Europe/Riga", "label": "Europe/Riga"},
            {"value": "Europe/Rome", "label": "Europe/Rome"},
            {"value": "Europe/Samara", "label": "Europe/Samara"},
            {"value": "Europe/San_Marino", "label": "Europe/San_Marino"},
            {"value": "Europe/Sarajevo", "label": "Europe/Sarajevo"},
            {"value": "Europe/Saratov", "label": "Europe/Saratov"},
            {"value": "Europe/Simferopol", "label": "Europe/Simferopol"},
            {"value": "Europe/Skopje", "label": "Europe/Skopje"},
            {"value": "Europe/Sofia", "label": "Europe/Sofia"},
            {"value": "Europe/Stockholm", "label": "Europe/Stockholm"},
            {"value": "Europe/Tallinn", "label": "Europe/Tallinn"},
            {"value": "Europe/Tirane", "label": "Europe/Tirane"},
            {"value": "Europe/Ulyanovsk", "label": "Europe/Ulyanovsk"},
            {"value": "Europe/Uzhgorod", "label": "Europe/Uzhgorod"},
            {"value": "Europe/Vaduz", "label": "Europe/Vaduz"},
            {"value": "Europe/Vatican", "label": "Europe/Vatican"},
            {"value": "Europe/Vienna", "label": "Europe/Vienna"},
            {"value": "Europe/Vilnius", "label": "Europe/Vilnius"},
            {"value": "Europe/Volgograd", "label": "Europe/Volgograd"},
            {"value": "Europe/Warsaw", "label": "Europe/Warsaw"},
            {"value": "Europe/Zagreb", "label": "Europe/Zagreb"},
            {"value": "Europe/Zaporozhye", "label": "Europe/Zaporozhye"},
            {"value": "Europe/Zurich", "label": "Europe/Zurich"},
            {"value": "Indian/Antananarivo", "label": "Indian/Antananarivo"},
            {"value": "Indian/Chagos", "label": "Indian/Chagos"},
            {"value": "Indian/Christmas", "label": "Indian/Christmas"},
            {"value": "Indian/Cocos", "label": "Indian/Cocos"},
            {"value": "Indian/Comoro", "label": "Indian/Comoro"},
            {"value": "Indian/Kerguelen", "label": "Indian/Kerguelen"},
            {"value": "Indian/Mahe", "label": "Indian/Mahe"},
            {"value": "Indian/Maldives", "label": "Indian/Maldives"},
            {"value": "Indian/Mauritius", "label": "Indian/Mauritius"},
            {"value": "Indian/Mayotte", "label": "Indian/Mayotte"},
            {"value": "Indian/Reunion", "label": "Indian/Reunion"},
            {"value": "Pacific/Apia", "label": "Pacific/Apia"},
            {"value": "Pacific/Auckland", "label": "Pacific/Auckland"},
            {"value": "Pacific/Bougainville", "label": "Pacific/Bougainville"},
            {"value": "Pacific/Chatham", "label": "Pacific/Chatham"},
            {"value": "Pacific/Chuuk", "label": "Pacific/Chuuk"},
            {"value": "Pacific/Easter", "label": "Pacific/Easter"},
            {"value": "Pacific/Efate", "label": "Pacific/Efate"},
            {"value": "Pacific/Fakaofo", "label": "Pacific/Fakaofo"},
            {"value": "Pacific/Fiji", "label": "Pacific/Fiji"},
            {"value": "Pacific/Funafuti", "label": "Pacific/Funafuti"},
            {"value": "Pacific/Galapagos", "label": "Pacific/Galapagos"},
            {"value": "Pacific/Gambier", "label": "Pacific/Gambier"},
            {"value": "Pacific/Guadalcanal", "label": "Pacific/Guadalcanal"},
            {"value": "Pacific/Guam", "label": "Pacific/Guam"},
            {"value": "Pacific/Honolulu", "label": "Pacific/Honolulu"},
            {"value": "Pacific/Kanton", "label": "Pacific/Kanton"},
            {"value": "Pacific/Kiritimati", "label": "Pacific/Kiritimati"},
            {"value": "Pacific/Kosrae", "label": "Pacific/Kosrae"},
            {"value": "Pacific/Kwajalein", "label": "Pacific/Kwajalein"},
            {"value": "Pacific/Majuro", "label": "Pacific/Majuro"},
            {"value": "Pacific/Marquesas", "label": "Pacific/Marquesas"},
            {"value": "Pacific/Midway", "label": "Pacific/Midway"},
            {"value": "Pacific/Nauru", "label": "Pacific/Nauru"},
            {"value": "Pacific/Niue", "label": "Pacific/Niue"},
            {"value": "Pacific/Norfolk", "label": "Pacific/Norfolk"},
            {"value": "Pacific/Noumea", "label": "Pacific/Noumea"},
            {"value": "Pacific/Pago_Pago", "label": "Pacific/Pago_Pago"},
            {"value": "Pacific/Palau", "label": "Pacific/Palau"},
            {"value": "Pacific/Pitcairn", "label": "Pacific/Pitcairn"},
            {"value": "Pacific/Pohnpei", "label": "Pacific/Pohnpei"},
            {"value": "Pacific/Port_Moresby", "label": "Pacific/Port_Moresby"},
            {"value": "Pacific/Rarotonga", "label": "Pacific/Rarotonga"},
            {"value": "Pacific/Saipan", "label": "Pacific/Saipan"},
            {"value": "Pacific/Tahiti", "label": "Pacific/Tahiti"},
            {"value": "Pacific/Tarawa", "label": "Pacific/Tarawa"},
            {"value": "Pacific/Tongatapu", "label": "Pacific/Tongatapu"},
            {"value": "Pacific/Wake", "label": "Pacific/Wake"},
            {"value": "Pacific/Wallis", "label": "Pacific/Wallis"},
            {"value": "UTC", "label": "UTC"}
        ],
        'menus': [
            {
                label: 'dashboard',
                value: 'dashboard',
                link: '/dashboard',
                icon: 'las la-tachometer-alt',
                tx: '',
                visible:true,
                hasSub: false,
                list: []
            },
            {
                label: 'charge-page',
                value: 'charge',
                link: '/charge',
                icon: 'las la-file-invoice',
                hasSub: false,
                visible: 'charge_view',
                tx: '',
                list: []
            }, {
                label: 'settlementlist',
                value: 'settlementlist',
                link: '/settlementlist',
                icon: 'lab la-searchengin',
                visible: 'settlement_view' ,
                hasSub: false,
                tx: '',
                list: []
            },
            {
                label: 'currency Wallet',
                value: 'currencyWallet',
                link: '/currencywallet',
                icon: 'las la-wallet',
                hasSub: false,
                visible: 'cryptowallet_view',
                tx: '',
                list: []
            },
            {
                label: 'reports',
                value: 'reports',
                link: '/reports',
                icon: 'las la-chart-bar',
                visible: 'report_view' ,
                hasSub: false,
                tx: '',
                list: []
            },
            {
                label: 'transactions',
                value: 'transactions',
                link: '/transactions',
                icon: 'las la-exchange-alt',
                visible: 'transactions_view',
                hasSub: false,
                tx: '',
                list: []
            },


            {
                label: 'transaction logs',
                value: 'transactionlogs',
                link: '/transactionlogs',
                icon: 'las la-search-dollar',
                visible:'transactionlogs_view',
                hasSub: false,
                tx: '',
                list: []
            }, {
                label: 'Offline transactions',
                value: 'SemiAutomatic',
                link: '/semiautomatic',
                icon: 'las la-university',
                visible: 'offlinetransactions_view' ,
                hasSub: false,
                tx: '',
                list: []
            },
            {
                label: 'withdraw',
                value: 'withdraw',
                link: '/withdraw',
                icon: 'las la-file-invoice-dollar',
                visible: 'withdrawals_view',
                hasSub: false,
                tx: '',
                list: []
            },
            {
                label: 'destination card',
                value: 'dstcard',
                link: '/dstcards',
                icon: 'lab la-cc-mastercard',
                visible:'settlement_view',
                hasSub: false,
                tx: '',
                list: []
            },

            {
                label: 'gateways',
                value: 'gateways',
                link: '/gateways',
                icon: 'las la-credit-card',
                visible:'gateways_view',
                hasSub: false,
                tx: '',
                list: []
            },

            {
                label: 'currency-page',
                value: 'currency',
                link: '/currency',
                icon: 'las la-coins',
                visible:'currencysettings_view',
                hasSub: false,
                tx: '',
                list: []
            },
            {
                label: 'bans-page',
                value: 'bans',
                link: '/bans',
                icon: 'las la-ban',
                visible:'bans_view',
                hasSub: false,
                tx: '',
                list: []
            },
            {
                label: 'employs',
                value: 'employs',
                link: '/employ',
                visible:'employee_view',
                icon: 'las la-user-astronaut',
                hasSub: false,
                tx: '',
                list: []
            },


            {
                label: 'support',
                value: 'support',
                link: '/support',
                icon: 'las la-comments',
                visible: true,
                hasSub: false,
                tx: localStorage.getItem('chat-support') === '1' ? 'New' : '',
                list: []
            },
            {
                label: 'Domain',
                value: 'domain',
                link: '/domain',
                icon: 'las la-registered',
                visible:'domain_view',
                hasSub: false,
                tx: '',
                list: []
            },


            {
                label: 'docs',
                value: 'docs',
                link: '/docs',
                icon: 'las la-folder-open',
                visible:true,
                hasSub: false,
                tx: '',
                list: []
            }


            //,{
            //     label: 'transaction',
            //     value: 'transaction',
            //     link: '#',
            //     icon: 'bx bx-map',
            //     hasSub: true,
            //     list: [
            //         {
            //             label: 'dashboard',
            //             link: '/',
            //             value: 'transaction-01',
            //         }
            //     ]
            // }
        ]
    })
    const messagesSupport = useRef([])
    const messagesSupportFirstTime = useRef(0)
    const [page, setPage] = useState(document.location.pathname.replace(window.configX.root, ''));
    const divRefSocketChat = useRef(null);
    const [loadingSocketPm, setLoadinSocketPm] = useState(false);
    const [loadingTextSocketPm, setLoadinTextSocketPm] = useState('');
    const [messagesSupportArr, setMessagesSupportArr] = useState([]);
    const [menuRefresh, setMenuRefresh] = useState(false);


    const pageRef = useRef(document.location.pathname.replace(window.configX.root, ''))
    const scrollToBottom = () => {
        if (document.getElementById('message-list') === null) return;
        const ul = document.getElementById('message-list');
        // Check if ul exists and has list items
        if (ul && ul.children.length > 0) {
            // Find the last li element
            const lastLi = ul.lastElementChild;
            // Scroll the ul element to make the last li element visible
            lastLi.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
    };

    function playSound() {

        try {
            if (localStorage.getItem('sound-support') === '1') {
                const audio = new Audio(mp3);
                audio.play();
            }
        } catch (e) {
            console.log(e)
        }
    }

    // Function to handle scrolling down
    const handleScrollDown = () => {

        scrollToBottom();
    };


    const toastID = useRef(0)
    htmlReader.setAttribute('data-theme-mode', setting.current["data-theme-mode"])
    htmlReader.setAttribute('data-menu-styles', setting.current["data-menu-styles"])
    htmlReader.setAttribute('data-header-styles', setting.current["data-header-styles"])

    const Gateways = {
        '1': {label: translator('C2c'), value: '1'},
        '2': {label: translator('Semi c2c'), value: '2'},
        '3': {label: translator('Pol network'), value: '3'},
        '4': {label: translator('Acc to acc'), value: '4'},
        '5': {label: translator('Perfect voucher'), value: '5'},
        '6': {label: translator('Perfect direct'), value: '6'},
        '7': {label: translator('Crypto'), value: '7'},
        '8': {label: translator('Focher'), value: '8'},
        '9': {label: translator('Shaparak'), value: '9'},
    }

    function translator(o) {
        if (lang[setting.current.lang] === undefined) return o;
        if (lang[setting.current.lang][o] === undefined) return o;
        return lang[setting.current.lang][o];
    }

    async function ajax(url, params, mode = 'add', method = 'POST') {

        if (authToken !== '') {
            params['token'] = authToken;
        }

        const ds = {"content-type": "application/x-www-form-urlencoded"};
        const xxx = Object.keys(params).map(key => key + '=' + params[key]).join('&')

        if(url.includes('/panel')){
            var xx = url.replace('/panel/', '').replace('/','_');

            if(checkPremision(xx) === false){

                return  false;
            }
        }


        const response = fetch(window.configX.url + url, {
            method: method,
            headers: ds,
            body: xxx
        });

        let res = await response;


        try {
            const result = res.json();
            if (res.status !== 200) {
                result.then(function (value) {
                    if (value.status === -1) {
                        if (value.message === 'auth5') {
                            localStorage.removeItem('authToken')
                            setAuthToken('')
                        }
                    }
                })


                NotificationButton('error', translator('Unfortunately, an error has occurred, please contact support'));
                return false;
            }

            if (mode === 'add' || mode === 'update' || mode === 'delete') {
                result.then(function (value) {
                    //{"status":200,"message":"gtw8","detail":"new payment gateway has been created successfully!"}
                    var bfg = translator(value.message);
                    if (bfg === value.message) {
                        bfg = value.detail;
                    }
                    if (bfg === '') {
                        bfg = translator('chrg3');
                    }
                    if (value.status === 200) {

                        NotificationButton('success', bfg)
                    } else if (value.status === -1) {
                        NotificationButton('error', bfg)
                        if (value.message === 'auth5') {
                            localStorage.removeItem('authToken')
                            setAuthToken('')
                        }

                    } else {
                        NotificationButton('error', bfg)
                    }
                })
            }
            return result;
        } catch (e) {
            NotificationButton('error', translator('Unfortunately, an error has occurred, please contact support'));

            return false;
        }

    }

    function timestampToUtcTimestamp(time, setZeroClock = 0) {
        // Convert the input timestamp to milliseconds
        const timestamp = parseInt(time) * 1000;

        // Create a date object using the timestamp
        const date = new Date(timestamp);

        // Adjust for the user's timezone
        // setUserTimezonee +3GMT is 3
        const userTimezoneOffsetMinutes = new Date().getTimezoneOffset(); // -180

        // Adjust the date for the user's timezone
        const userTimezoneDate = new Date(date.getTime() + userTimezoneOffsetMinutes * 60000);

        // If setZeroClock is true, set the time to 00:00:00
        if (setZeroClock === 1) {
            userTimezoneDate.setHours(0, 0, 0, 0);
        }

        // Convert the adjusted date back to UTC
        const utcDate = new Date(userTimezoneDate.getTime() - userTimezoneOffsetMinutes * 60000);

        // Return the timestamp in seconds
        return (utcDate.getTime() / 1000);
    }

    function handleDatePickerFocus(d) {
        // Reset the time to '00:00:00' when the date picker is focused
        const newDateTime = new Date();
        newDateTime.setHours(0, 0, 0, 0);
        if (parseInt(d) === 1) {
            return moment(newDateTime);
        }
        return momentJalaali(newDateTime);
    }

    function changeTimer(time, mdoe = 0) {
        if (time === 0 || time === '0' || time === '') {
            return '-';
        }
        if (parseInt(time) === 0) {
            return '-';
        }

        const date = momentJalaali(time * 1000);
        // const persianDate = momentJalaali(date);

        var tome = localStorage.getItem('timezone-paypal');
        if (tome === null) {
            tome = '';
        }
        if (tome === undefined) {
            tome = '';
        }
        if (tome !== '') {
            date.tz(tome)
        }


        if (localStorage.getItem('calender-paypal') !== 'georgian') {
            if (mdoe === 0) {
                return momentJalaali(date).format("jYYYY/jMM/jDD HH:mm:ss");
            }

            if (mdoe === 1) {
                return momentJalaali(date).format("jYYYY/jMM/jDD");
            }
            if (mdoe === 2) {
                return momentJalaali(date).format("HH:mm:ss");
            }
        } else {
            if (mdoe === 0) {
                return momentJalaali(date).format("YYYY/MM/DD HH:mm:ss");
            }

            if (mdoe === 1) {
                return momentJalaali(date).format("YYYY/MM/DD");
            }
            if (mdoe === 2) {
                return momentJalaali(date).format("HH:mm:ss");
            }

        }
    }

    const selectMenu = (list, value, change, label, isRequire = 1, className = 'col-lg-2 col-md-6 col-12 mb-lg-0 mb-2 mt-2') => {

        return (
            <div className={className}>
                <label htmlFor={label + 's'}>
                    {translator(label)}{" "}
                    {isRequire === 1 && <span className="text-danger">*</span>}
                </label>

                <select id={label + 's'} value={value} onChange={change}
                        className="form-control datatable-input form-select">
                    <option value="">
                        {translator("choose")}
                    </option>
                    {list.map(function (v, o) {
                        return (
                            <option key={'sdsd' + o} value={v.value}>{v.label}</option>
                        )
                    })}
                </select>
            </div>
        )
    }

    function getBankLogo(cardNumber) {
        var cardPan = cardNumber.substr(0, 6);
        var logoFileName;
        var logo = defaultIcon;


        switch (cardPan) {
            case '603769':
                logoFileName = 'saderat';
                logo = saderat;
                break;
            case '627488':
                logoFileName = 'karafarin';
                logo = karafarin;
                break;
            case '628023':
                logoFileName = 'maskan';
                logo = maskan;
                break;
            case '610433':
                logoFileName = 'mellat';
                logo = mellat;
                break;
            case '610434':
                logoFileName = 'mellat';
                logo = mellat;
                break;
            case '603799':
                logoFileName = 'meli';
                logo = meli;
                break;
            case '502229':
                logoFileName = 'pasargad';
                logo = pasargad;
                break;
            case '585983':
                logoFileName = 'tejarat';
                logo = tejarat;
                break;
            case '627353':
                logoFileName = 'tejarat';
                logo = tejarat;
                break;
            case '622106':
                logoFileName = 'parsian';
                logo = parsian;
                break;
            case '627412':
                logoFileName = 'eghtesad';
                logo = eghtesad;
                break;
            case '636214':
                logoFileName = 'ayandeh';
                logo = ayandeh;
                break;
            case '186214':
                logoFileName = 'ayandeh';
                logo = ayandeh;
                break;
            case '502938':
                logoFileName = 'day';
                logo = day;
                break;
            case '621986':
                logoFileName = 'saman';
                logo = saman;
                break;
            case '627381':
                logoFileName = 'ansar';
                logo = ansar;
                break;
            case '504706':
                logoFileName = 'shahr';
                logo = shahr;
                break;
            case '627760':
                logoFileName = 'postbank';
                logo = postbank;
                break;
            case '639370':
                logoFileName = 'mehreqtesad';
                logo = mehreqtesad;
                break;
            case '639607':
                logoFileName = 'sarmaye';
                logo = sarmaye;
                break;
            case '639599':
                logoFileName = 'ghavamin';
                logo = ghavamin;
                break;
            case '636949':
                logoFileName = 'hekmat';
                logo = hekmat;
                break;
            case '627961':
                logoFileName = 'sanatmadan';
                logo = sanatmadan;
                break;
            case '505416':
                logoFileName = 'gardeshgari';
                logo = gardeshgari;
                break;
            case '505785':
                logoFileName = 'iranzamin';
                logo = iranzamin;
                break;
            case '585947':
                logoFileName = 'khavarmiyaneh';
                logo = khavarmiyaneh;
                break;
            case '505809':
                logoFileName = 'khavarmiyaneh';
                logo = khavarmiyaneh;
                break;
            case '589463':
                logoFileName = 'refah';
                logo = refah;
                break;
            case '589210':
                logoFileName = 'sepah';
                logo = sepah;
                break;
            case '604932':
                logoFileName = 'sepah';
                logo = sepah;
                break;
            case '603770':
                logoFileName = 'keshavarsi';
                logo = keshavarsi;
                break;
            case '636795':
                logoFileName = 'markazi';
                logo = markazi;
                break;
            case '010060':
                logoFileName = 'markazi';
                logo = markazi;
                break;
            case '507677':
                logoFileName = 'etebarinoor';
                logo = etebarinoor;
                break;
            case '606373':
                logoFileName = 'bank-mehr-iran';
                logo = bankmehriran;
                break;
            case '581874':
                logoFileName = 'iranvenezoela';
                logo = iranvenezoela;
                break;
            case '505801':
                logoFileName = 'kosar';
                logo = kosar;
                break;
            case '606256':
                logoFileName = 'melal';
                logo = melal;
                break;
            case '504165':
                logoFileName = 'etebarisamen';
                logo = etebarisamen;
                break;
            case '628157':
                logoFileName = 'etebaritosee';
                logo = etebaritosee;
                break;
            case '504172':
                logoFileName = 'resalat';
                logo = resalat;
                break;
            case '502908':
                logoFileName = 'tosehe';
                logo = tosehe;
                break;
            case '627648':
                logoFileName = 'toseesaderat';
                logo = toseesaderat;
                break;
            case '639346':
                logoFileName = 'sina';
                logo = sina;
                break;
            default:
                logoFileName = 'default';
                break;
        }
        return {logoFileName, logo};
    }


    const bankWithCart = (data) => {


        if (data.length === 16) {
            var logo = getBankLogo(data);
            return <div onClick={() => {
                copy(data)
                NotificationButton('info', translator('copy is successfully'), 700)
            }}>
                <OverlayTrigger
                    placement="auto"

                    overlay={<Tooltip id="tooltip-top">{data} - {translator(logo['logoFileName'])} </Tooltip>}
                    className={'d-flex justify-content-center align-items-center'}>
                   <span dir={'ltr'}>
                         <span style={{
                             display: "flex",
                             flexDirection: "row",
                             alignItems: "center"
                         }}>
                        <img style={{marginTop:'-5px'}} src={logo['logo']} width={20}/>
                             &nbsp;

                             {splitFourToFour(data)}

                   </span>

                   </span>
                </OverlayTrigger>
            </div>;
        } else {
            return <div onClick={() => {
                copy(data)
                NotificationButton('info', translator('copy is successfully'), 700)
            }}>
                <OverlayTrigger
                    placement="auto"

                    overlay={<Tooltip id="tooltip-top">{data}</Tooltip>}
                    className={'d-flex justify-content-center align-items-center'}>
                   <span dir={'ltr'}>
                        {splitFourToFour(data)}

                   </span>
                </OverlayTrigger>
            </div>;
        }

    }

    const textMenu = (value, change, type = 'text', label, isRequire = 1, placeHolder = '', className = 'col-lg-3 col-md-6 col-12 mb-lg-0 mb-2 mt-2', readAble = false, hasBtn = false, btnFunc, btnColor, btnIcon, texticon = '', loadICo = false) => {

        return (
            <div className={className}>
                <label htmlFor={label + 's'}>
                    {translator(label)}{" "}
                    {isRequire === 1 && <span className="text-danger">*</span>}
                </label>

                {
                    hasBtn === false && <input
                        type="text"
                        readOnly={readAble}
                        disabled={readAble}
                        id={label + 's'}
                        value={value}
                        onChange={change}
                        className="form-control datatable-input"
                        placeholder={translator(placeHolder)}

                    />
                }
                {
                    hasBtn === true && <div className={'d-flex'}>
                        <input
                            type={type}
                            readOnly={readAble}
                            disabled={readAble}
                            id={label + 's'}
                            value={value}
                            onChange={change}
                            className="form-control datatable-input"
                            placeholder={translator(placeHolder)}

                        />
                        {loadICo === true ?
                            <div className={"p-2 input-group-text bg-" + btnColor + "   text-white"}><ReactLoading
                                type={'cubes'}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '20px',
                                    width: '20px',
                                    color: 'var(--menu-bg)',
                                    fill: 'var(--menu-bg)',
                                }}
                                color={'white'}/></div> :
                            <div onClick={btnFunc} className={"p-2 input-group-text bg-" + btnColor + "   text-white"}><i
                                className={btnIcon}></i> &nbsp;{texticon !== '' ? texticon : texticon}</div>}
                    </div>
                }


            </div>
        )
    }

    const DatePickerFunc = (fd = 0, date, setDate, label = '', time = 0, className = 'col-lg-2 col-md-6 col-12 mb-lg-0 mb-2 mt-2') => {

        return (
            <div onClick={() => {
                if (date === null) {
                    if (fd === 0) {
                        setDate(momentJalaali().startOf('day'))
                    } else {

                        setDate(momentJalaali().endOf('day'))
                    }
                }
            }} className={className}>
                <label> {translator(label)}:</label>
                <div className={'d-flex'}>
                    <DatePicker

                        value={date}

                        timePicker={time === 1}
                        isGregorian={setting.current.calender === 'georgian'}
                        inputJalaaliFormat={time === 0 ? "jYYYY-jMM-jDD" : "jYYYY-jMM-jDD HH:mm:ss"}
                        persianDigits={false}
                        inputFormat={time === 0 ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss"}
                        onChange={(e) => {
                            setDate(e)
                        }}
                        className={'form-control  dash-filter-picker  flatpickr-input active'}
                    />
                    <div onClick={() => {

                        setDate(null);
                    }} className="p-2 input-group-text bg-danger-transparent border-info text-white"><i
                        className="lar la-trash-alt"></i></div>
                </div>
            </div>

        )
    }

    function splitCardInput(v) {

        var sd = '';
        for (var i = 0; i < 16; i++) {
            if (v[i] === undefined) {
                break;
            }
            sd += v[i];
            if (i === 3 || i === 7 || i === 11) {
                if (v[i + 1] === undefined) {
                    break;
                }
                sd += '-'
            }
        }
        return sd;
    }

    function splitFourToFour(str, maxLength = 10) {

        if (str.length <= maxLength) {
            return str;
        } else {

            return str.slice(0, 4) + '.....' + str.slice(-4);
        }
    }

    const btnFunction = (loading = false, icon = 'las la-save', label = 'Save', func, className = 'col-lg-2   col-md-4 col-12 m-2 btn btn-wave btn btn-success-gradient') => {

        return (
            loading === false ? <button onClick={func} className={className}>
                  <span>
                      <i className={icon}></i>{" "}
                      <span>{translator(label)}</span>{" "}
                  </span>
                </button> :
                <button className={className}>
                    <ReactLoading type={'cubes'}
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      height: '25px',
                                      width: '97px',
                                      color: 'var(--menu-bg)',
                                      fill: 'var(--menu-bg)',
                                  }}
                                  color={'white'}/>
                </button>
        )
    }

    function addCommos(event, nm = 'tom') {

        if (event === '-') return '-';
        var nStr = event + "";
        var x = nStr.split(".");
        var x1 = x[0];
        var x2 = x.length > 1 ? "." + x[1] : "";
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, "$1" + "," + "$2");
        }
        var ret = x1 + x2.slice(0, counterCommos(nm, event));
        if (ret.toString() === 'NaN') ret = '';
        return ret;
    }

    function counterCommos(currency, amount = null) {
        if (amount !== null) {
            if (amount < 0) amount = amount * -1;
            if (amount > 0 && amount < 1) {
                var x = (amount + '').split(".");
                var number = '';
                var counter = 0;
                for (var i = 0; i < x[1].length; i++) {
                    if (parseInt(x[1][i]) > 0) {
                        counter++;
                    }
                    if (counter === 1 && parseInt(x[1][i]) === 0) {
                        counter = 3;
                    }
                    if (counter > 1) {
                        break
                    }
                    number += x[1][i];
                }
                return (number.length) + 1;
            }
        }

        currency = currency.toLowerCase();
        if (currency === 'tom') return 0;
        if (currency === 'btc') return 6;

        return 3;
    }

    function removeCommos(event) {
        if (event === '-') return '-';
        return event.replace(/,/g, '');
    }

    function NotificationButton(m, msg, timer = 2500) {
        try {
            toast.dismiss('s' + toastID.current);
            toastID.current++;
            setTimeout(function () {
                toast(msg, {
                    toastId: 's' + toastID.current,
                    position: "top-center",
                    hideProgressBar: false,
                    autoClose: timer,
                    className: m === 'error' ? 'bg-danger text-white' : m === 'info' ? 'bg-info text-white' : 'bg-success text-white'
                });
            }, 200)


        } catch (e) {
        }
    }

    const loadBarAcc = () => {
        return <div style={{position: "absolute", top: '35%', left: '45%', width: '50%'}}>
            <ReactLoading type={'bars'}
                          color={'white'}
                          height={'20%'}
                          width={'20%'}/>
        </div>
    }

    function showMessage(icon, title, text, timer = 3000) {
        Swal.fire({
            icon: icon,
            title: title,
            text: text,
            timer: timer,
            showConfirmButton: false,
            buttons: false
        })
    }

    function generateRandomNumber() {
        const min = Math.pow(10, 9); // Minimum 10-digit number
        const max = Math.pow(10, 10) - 1; // Maximum 10-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function getTimezone(timezone, t = '') {


        const currentDate = t === '' ? new Date() : new Date(parseInt(t));
        var Obj = {

            timeZoneName: 'short'
        }
        if (timezone !== '') {
            Obj['timeZone'] = timezone;
        }

        var dx = Intl.DateTimeFormat('en-US', Obj).formatToParts(currentDate).find(part => part.type === 'timeZoneName').value;


        return convertTimezoneStringToOffset(dx);
        // Update the state with the timezone offset

    }

    function convertTimezoneStringToOffset(timezoneString) {

        // Split the timezone string into its components

        var parts = timezoneString.split(/[+-]/);

        if (parts.length === 1) {
            return 0
        }
        parts = parts[1].split(':')
        // Extract the hours and minutes from the string
        const hours = parseInt(parts[0] === undefined ? 0 : parts[0]);
        const minutes = parseInt(parts[1] === undefined ? 0 : parts[1]);
        // Calculate the total offset in minutes
        const offset = (hours * 60) + minutes;
        // Determine the sign of the offset
        const sign = timezoneString.includes('+') ? 1 : -1;
        // Return the offset as an integer
        return offset * sign;
    }

    function addReplayMessage(message) {
        // Ensure messagesSupport.current is initialized
        if (!messagesSupport.current) {
            console.error("messagesSupport.current is not initialized!");
            return;
        }


        if (pageRef.current !== '/support') {
            toastID.current++;
            toast(message, {
                toastId: 's' + toastID.current,
                position: "top-right",
                hideProgressBar: false,
                autoClose: 5000,
                className: 'bg-info text-white'
            });
            localStorage.setItem('chat-support', '1')
            if (document.getElementById('support-id') !== null) {
                document.getElementById('support-id').innerText = 'New'
                document.getElementById('support-id').style.display = 'inline'
            }

        }

        const msg = {
            message: message,
            sender: 1,
            status: 2,
            time: new Date().getTime() / 1000,
            username: 'admin',
        };
        messagesSupport.current = [...messagesSupport.current, msg];
        setMessagesSupportArr(prevMessages => [...prevMessages, msg]);

        playSound();

        setTimeout(handleScrollDown, 500);
    }

    function conect() {


        pnum.current = 0;

        if (authToken === '') {
            return;
        }
        let url = 'wss://support.' + window.configX.url.replace('https://', '').replace('http://', '') + ':8443/' + authToken;
        if (window.configX.url === '') {
            url = 'wss://support.' + window.location.hostname + ':8443/' + authToken;
        }
        ws.current = new WebSocket(url);

        ws.current.onopen = function (event) {
            setLoadinSocketPm(true)
            setLoadinTextSocketPm(translator('Loading page Please Wait for a seconds'))
        };

        ws.current.onmessage = function (event) {
            var jsonData = JSON.parse(event.data);
            setLoadinSocketPm(false)
            setLoadinTextSocketPm('')
            if (jsonData.command === 'ping') {
                sendMessage('pong', '');
            }
            if (jsonData.command === 'replay') {
                addReplayMessage(jsonData.message);
            }
            if (jsonData.command === 'closeConnection') {
                NotificationButton('error', jsonData.message);
            }
        }
        ws.current.onerror = function (error) {
            console.log('onerror')
            console.log(error);
        };

        ws.current.onclose = function (event) {
            console.log('onclose')
            console.log(event)
            setLoadinSocketPm(true)
            setting.current.setting++;
            setLoadinTextSocketPm(translator('Loading page Please Wait for a seconds'))
            if (setting.current.setting > 4) {
                return;
            }

            setTimeout(function () {
                conect()
            }, 5000)
        }


    }

    function sendMessage(type, message) {
        pnum.current++;
        var obj = {}
        obj.response = type;
        obj.message = message;
        obj.pNum = pnum.current;
        try {
            if (ws.current !== null && ws.current !== undefined) {
                ws.current.send(JSON.stringify(obj));
            }

            return true;
        } catch (err) {
            return false;
        }
    }

    const balanceFunc = () => {
        ajax('/panel/dashboard/balance', {}, 'view').then(function (value) {

            if (value !== false && value.data !== undefined) {
                setBalance(value.data)
            }
        })
    }

    function checkPremision(chk) {


        if(setting === null){
            return false;
        }


        if(chk.includes("getcontent")){
            return true;
        }
        if(chk.includes("supports")){
            return true;
        }

        if(setting.current === null){
            return false;
        }
        if(setting.current.permission === false) {
            return  false;
        }
        if(setting.current.permission.includes("superAdmin")){
            return true;
        }
        if(setting.current.permission.includes("full")){
            return true;
        }

        if(setting.current.permission.includes(chk)){
            return true;
        }
        return false;
    }
    if(localStorage.getItem('authToken') !== null && localStorage.getItem('permissionPay') === null )
    {
        localStorage.removeItem("authToken")
        setAuthToken("")

    }

    useEffect(function () {
        balanceFunc()
    }, [document.location.pathname])


    useEffect(function () {

        const ur = document.location.pathname.replace(window.configX.root, '');
        pageRef.current = ur;


        if (ur === '/support') {
            if (ws.current === null || ws.current === undefined) {
                conect()
            }
        }
    }, [document.location.pathname])


    return <DataContext.Provider value={{
        OverlayTrigger: OverlayTrigger,
        Tooltip: Tooltip,
        balance: balance,
        loadingSocketPm: loadingSocketPm,
        setLoadinSocketPm: setLoadinSocketPm,
        loadingTextSocketPm: loadingTextSocketPm,
        setLoadinTextSocketPm: setLoadinTextSocketPm,
        checkPremision: checkPremision,
        ajax: ajax,
        Gateways: Gateways,
        addCommos: addCommos,
        removeCommos: removeCommos,
        generateRandomNumber: generateRandomNumber,
        selectMenu: selectMenu,
        textMenu: textMenu,
        showMessage: showMessage,
        NotificationButton: NotificationButton,
        translator: translator,
        DataTableHandler: DataTableHandler,
        splitCardInput: splitCardInput,
        splitFourToFour: splitFourToFour,
        timestampToUtcTimestamp: timestampToUtcTimestamp,
        changeTimer: changeTimer,
        handleDatePickerFocus: handleDatePickerFocus,
        getTimezone: getTimezone,
        btnFunction: btnFunction,
        NavLink: NavLink,
        DatePickerFunc: DatePickerFunc,
        page: page,
        setPage: setPage,

        Loading: Loading,
        pageRef: pageRef.current,
        playSound: playSound,
        sendMessage: sendMessage,
        logo: logo,
        messagesSupportFirstTime: messagesSupportFirstTime,
        messagesSupport: messagesSupport,
        handleScrollDown: handleScrollDown,
        logoWhite: logoWhite,
        Modal: Modal,
        ModalHeader: ModalHeader,
        Accordion: Accordion,
        ModalBody: ModalBody,
        ModalFooter: ModalFooter,
        timeZones: setting.current.timeZones,
        setMyTimeZone: setMyTimeZone,
        myTimeZone: myTimeZone,
        menuRefresh: menuRefresh,
        ReactLoading: ReactLoading,
        setMenuRefresh: setMenuRefresh,
        SupportTokens: {

            'usdt': 'Theter(USDT)',
            'btc': 'Bitcoin(BTC)',
            'eth': 'Ethereum(ETH)',
            'ltc': 'Litecoin(LTC)',
            'doge': 'Dogecoin(DOGE)',
            'trx': 'Tron(TRX)',
            'sun': 'Sun(SUN)',
            'win': 'WinkLink(WIN)',
            'jst': 'Just(JST)',
            'btt': 'Bittorent(BTT)',
        },


        SupportTokensLogo: setting.current.toks,

        Offcanvas: Offcanvas,
        divRefSocketChat: divRefSocketChat,
        setting: setting.current,
        htmlReader: htmlReader,
        activeMenu: activeMenu,
        setActiveMenu: setActiveMenu,
        activeSubMenu: activeSubMenu,
        setActiveSubMenu: setActiveSubMenu,
        setRefresh: setRefresh,
        closeMe: closeMe,
        setCloseMe: setCloseMe,
        close: close,
        setClose: setClose,
        loadBarAcc: loadBarAcc,
        refresh: refresh,
        authToken: authToken,
        Swal: Swal,
        copy: copy,
        Dropdown: Dropdown,
        SelectDb: Select,
        messagesSupportArr: messagesSupportArr,
        setMessagesSupportArr: setMessagesSupportArr,
        momentJalaali: momentJalaali,
        moment: moment,
        getBankLogo: getBankLogo,
        bankWithCart: bankWithCart,
        setAuthToken: setAuthToken,
        pools: {
            '0': pool3,
            '1': pool2,
            '2': pool1,
        }


    }}>{props.children}</DataContext.Provider>

}

export default DataContext;
